import { Box, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import ReviewIcons from '../../components/atoms/ReviewIcons/ReviewIcons';
import { Builder } from '@builder.io/react';

const PLPReview = ({ header, stars, reviewHeading, reviewText, textColor }) => {
  return (
    <Stack spacing={6} maxW={680}>
      <Box as='h3' fontSize={24}>{header}</Box>
      <div>
        <ReviewIcons rating={stars} hideExcess gap={1} />
        <Text mt={1} color={textColor}>
          {reviewHeading}
        </Text>
      </div>
      <Text color={textColor}>{reviewText}</Text>
    </Stack>
  );
};

Builder.registerComponent(PLPReview, {
  name: 'Text Review',
  inputs: [
    {
      name: 'header',
      type: 'string',
      defaultValue: 'Header'
    },
    {
      name: 'stars',
      type: 'number',
      defaultValue: 5,
      max: 5,
      min: 1
    },
    {
      name: 'reviewHeading',
      type: 'longText',
      defaultValue: 'Review Heading'
    },
    {
      name: 'reviewText',
      type: 'longText',
      defaultValue: 'Review Text'
    },
    {
      name: 'textColor',
      type: 'color',
      defaultValue: '#5D6975'
    }
  ]
});
