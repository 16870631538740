import { Builder } from '@builder.io/react';
import { Box, Flex, Image, Text, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import Slider from 'react-slick';

import { Link } from 'gatsby';
import * as styles from './CategorySlider.module.scss';

const CategorySlider = ({
  bannerImagePc,
  bannerImageMobile,
  bannerLink,
  categories
}) => {
  const [isMobile] = useMediaQuery('(max-width: 1023px)', {
    ssr: true,
    fallback: false // return false on the server, and re-evaluate on the client side
  });

  return (
    <div>
      {bannerLink ? (
        <Link to={bannerLink}>
          <Image
            w={'full'}
            m={0}
            src={isMobile ? bannerImageMobile : bannerImagePc || bannerImagePc}
            rounded={'lg'}
            mb={4}
            mixBlendMode={'multiply'}
          />
        </Link>
      ) : (
        <Image
          w={'full'}
          m={0}
          src={isMobile ? bannerImageMobile : bannerImagePc || bannerImagePc}
          rounded={'lg'}
          mb={4}
        />
      )}

      <Box className={styles.root}>
        {categories && categories.length > 0 && (
          <Slider
            dots={false}
            infinite={false}
            autoplay={false}
            slidesToShow={6}
            slidesToScroll={1}
            centerPadding="10px"
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 4
                }
              },
              {
                breakpoint: 650,
                settings: 'unslick'
              }
            ]}
          >
            {categories.map((category, index) => {
              return (
                <Link key={index} href={category.link ?? '/'}>
                  <Flex
                    className="shadow"
                    borderRadius={'16px'}
                    as="figure"
                    m={0}
                    py={4}
                    bg={'#F6F6F8'}
                    justify={'center'}
                    align={'center'}
                  >
                    <Image m={0} src={category.image} h={'50px'} w={'50px'}
                    mixBlendMode={'multiply'}/>
                  </Flex>

                  <Text fontSize={'14px'} mt={2} textAlign={'center'}>
                    {category.category}
                  </Text>
                </Link>
              );
            })}
          </Slider>
        )}
      </Box>
    </div>
  );
};
Builder.registerComponent(CategorySlider, {
  name: 'Category Slider',
  inputs: [
    {
      name: 'header',
      type: 'string',
      defaultValue: 'Category Slider'
    },
    {
      name: 'bannerLink',
      type: 'url',
      required: false
    },
    {
      name: 'bannerImagePc',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg', 'webp'],
      required: false
    },
    {
      name: 'bannerImageMobile',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg', 'webp'],
      required: false
    },
    {
      name: 'categories',
      type: 'list',
      subFields: [
        {
          name: 'category',
          type: 'string',
          defaultValue: 'Category'
        },
        {
          name: 'link',
          type: 'url',
          defaultValue: '/'
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg', 'webp'],
          required: true
        }
      ]
    }
  ]
});
