import { Builder } from '@builder.io/react';
import { Box, Image, Text, Flex } from '@chakra-ui/react';
import { Link } from 'gatsby';
import React from 'react';
import Icon from '../../components/atoms/Icon/Icon';

import * as pcStyles from './PLPBanner.module.scss';
import * as mpsStyles from './PLPBanner.mps.module.scss';
import { isMadPawsStore, storeStyle } from '../../common/util';

const styles = storeStyle({ mpsStyles, pcStyles });
export const PLPBanner = ({
  wrap,
  header,
  maxImageHeight,
  cardColor,
  category,
  borderColor,
  radius,
  grayScale,
  centerAlign,
  sliderOnMobile,
  removePadding,
  removeMargin,
  captionInside,
  seeMoreLink,
  hideLinkOnMobile
}) => {
  return (
    <div className={styles.root}>
      <Flex justifyContent={'space-between'} alignItems={'center'} mb={[4, 4, 8]}>
        {header && <h3>{header}</h3>}
        {seeMoreLink && (
          <Flex
            display={{
              base: hideLinkOnMobile ? 'none' : 'flex',
              sm: 'flex'
            }}
            gap={1}
            alignItems={'center'}
            as={'a'}
            href={seeMoreLink}
            color={isMadPawsStore() ? 'var(--primary-default)' : '#14617A'}
            className="underline"
          >
            <Text fontWeight={'bold'} fontSize={'15px'} whiteSpace={'nowrap'}>
              VIEW ALL
            </Text>{' '}
            <Icon symbol={isMadPawsStore() ? 'chevRightMps' : 'chevRight'} />
          </Flex>
        )}
      </Flex>

      <Box
        as="ul"
        justifyContent={['unset', 'unset', centerAlign ? 'center' : 'flex-start']}
        className={[wrap ? 'wrap' : '', sliderOnMobile ? 'mobile-slider' : ''].join(' ')}
      >
        {category.map((category, index) => (
          <Box
            className={grayScale ? 'grayscale' : ''}
            as="li"
            key={index}
            border={borderColor ? `1px solid ${borderColor}` : 'none'}
            borderRadius={radius ?? 0}
            cursor={'pointer'}
          >
            <Link to={category.url}>
              <Box
                as="figure"
                bgColor={cardColor ?? ''}
                position={'relative'}
                py={removePadding ? 0 : 4}
                mb={removeMargin ? 0 : 6}
                _hover={{
                  boxShadow: '3px 3px 7px 1px #ccc;',
                  transition: 'all 0.3s ease-in-out'
                }}
              >
                <Image maxH={maxImageHeight ?? 'auto'} src={category.image} alt="" m={0} />
                {category.tag && (
                  <Box
                    bg={isMadPawsStore() ? 'var(--secondary-light-1x)' : '#D12026'}
                    color={isMadPawsStore() ? 'var(--negative-default)' : 'white'}
                    rounded={'full'}
                    display={'inline-block'}
                    py={1}
                    px={4}
                    position={'absolute'}
                    bottom={-4}
                    left={'50%'}
                    transform={'translateX(-50%)'}
                    whiteSpace={'nowrap'}
                  >
                    {category.tag}
                  </Box>
                )}
                {captionInside && (
                  <Text
                    as={'figcaption'}
                    noOfLines={{
                      base: 1,
                      sm: 2
                    }}
                  >
                    {category.title}
                  </Text>
                )}
              </Box>
              {!captionInside && (
                <Text
                  as={'figcaption'}
                  noOfLines={{
                    base: 1,
                    sm: 2
                  }}
                >
                  {category.title}
                </Text>
              )}
            </Link>
          </Box>
        ))}
      </Box>
    </div>
  );
};

Builder.registerComponent(PLPBanner, {
  name: 'Category banner',
  inputs: [
    {
      name: 'header',
      type: 'string',
      defaultValue: 'Header'
    },
    {
      name: 'wrap',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'centerAlign',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'removeMargin',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'removePadding',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'captionInside',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'cardColor',
      type: 'color'
    },
    {
      name: 'borderColor',
      type: 'color'
    },
    {
      name: 'radius',
      type: 'number'
    },
    {
      name: 'grayScale',
      type: 'boolean',
      defaultValue: false
    },

    {
      name: 'sliderOnMobile',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'maxImageHeight',
      type: 'number',
      defaultValue: 130
    },
    {
      name: 'seeMoreLink',
      type: 'string',
      defaultValue: '#'
    },
    {
      name: 'category',
      type: 'list',
      defaultValue: [{ title: 'Cats' }],
      subFields: [
        {
          name: 'title',
          type: 'string',
          defaultValue: 'Cats'
        },
        {
          name: 'url',
          type: 'string',
          defaultValue: '/cats'
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          defaultValue:
            'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d'
        },
        {
          name: 'tag',
          type: 'string'
        }
      ]
    }
  ]
});
