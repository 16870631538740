import { graphql } from 'gatsby';
import React from 'react';
import '../builderComponents/CategorySlider/CategorySlider';
import '../builderComponents/PLPBanner/PLPBanner';
import '../builderComponents/PLPReview/PLPReview';
import Catalog from '../components/organisms/Catalog/Catalog';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';

export const query = graphql`
  query CategoryTemplateQuery($id: String!, $path: String!) {
    builder: allBuilderModels {
      onePlpBanner(target: { urlPath: $path }, options: { cachebust: true }) {
        content
      }
      onePlpBottomSection(target: { urlPath: $path }, options: { cachebust: true }) {
        content
      }
      onePlpBottomSeoContent(target: { urlPath: $path }, options: { cachebust: true }) {
        content
      }
    }
    brand: bigCommerceBrands(id: { eq: $id }) {
      name
      id
      bigcommerce_id
      page_title
      meta_description
      custom_url {
        url
        is_customized
      }
    }
  }
`;

const BrandTemplate = props => {
  const { data, errors, pageContext } = props;
  const headerContent = data && data.builder.onePlpBanner?.content;
  const footerContent = data && data.builder.onePlpBottomSection?.content;
  const seoContent = data && data.builder.onePlpBottomSeoContent?.content;
  const brand = data && data.brand;
  return (
    <Layout>
      {errors && <Seo title="GraphQL Error" />}
      {brand && <Seo title={brand.page_title} description={brand.meta_description} />}

      {brand && (
        <Catalog
          pageContext={pageContext}
          category={brand}
          isBrand={true}
          headerContent={headerContent}
          footerContent={footerContent}
          seoContent={seoContent?.data}
        />
      )}
    </Layout>
  );
};

export default BrandTemplate;
